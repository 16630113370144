<template>
  <div class='user'>
    <div class='globle_border' v-loading="loading">
      <div class="search">
        <el-select v-model="startStatus" placeholder="请选择" @change="onSearch" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input v-model="tendersName" placeholder="请输入标书名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="tendersNumber" placeholder="请输入标号" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-button type="primary" @click="handleAdd">添加</el-button>
      </div>
      <div class="table_list globle_table">
        <el-table :data="tableData" style="width: 100%;" max-height="540">
          <el-table-column prop="tendersNumber" label="标号" width="100">
            <template slot-scope="scope">
              <el-tooltip :content="scope.row.tendersNumber" placement="top">
                <span>{{ scope.row.tendersNumber }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="tendersName" label="标书名称" width="100">
            <template slot-scope="scope">
              <el-tooltip :content="scope.row.tendersName" placement="top">
                <span>{{ scope.row.tendersName }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="tendersType" label="招标类型" width="100" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.tendersType == 1 ? '商超' : '' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="createdUserName" label="发布人" width="100" align="center"></el-table-column>
          <el-table-column prop="createdTime" label="发布时间" width="180">
            <template slot-scope="scope">
              <el-tooltip :content="scope.row.createdTime" placement="top">
                <span>{{ scope.row.createdTime }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="applicationStartTime" label="报名开始时间" width="110"></el-table-column>
          <el-table-column prop="applicationEndTime" label="报名结束时间" width="110"></el-table-column>
          <el-table-column prop="payMoney" label="缴费金额" width="80"></el-table-column>
          <el-table-column prop="payEndTime" label="缴费截止时间" width="120"></el-table-column>
          <el-table-column prop="tendersTime" label="开标时间" width="120"></el-table-column>
          <el-table-column prop="effectiveStartTime" label="中标有效开始时间" width="140"></el-table-column>
          <el-table-column prop="effectiveEndTime" label="中标有效结束时间" width="140"></el-table-column>
          <el-table-column label="状态" width="90">
            <template slot-scope="scope">
              <span :class="scope.row.startStatus == 2 ? 'red' : 'gray'">{{ rowstatus(scope.row) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" fixed="right" width="250">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" plain @click="handleView(scope.row)" size="small">查看</el-button>
              <el-button v-if="scope.row.startStatus == 1" class="btn" type="" plain @click="handleEdit(scope.$index, scope.row)" size="small">修改</el-button>
              <el-button v-if="scope.row.startStatus == 1" class="btn" type="danger" plain @click="handleDelete(scope.$index, scope.row)" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

    <el-dialog :title="isAdd ? '新增' : '修改'" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="标号" prop="tendersNumber">
              <el-input controls v-model.number="ruleForm.tendersNumber" placeholder="请输入标号"></el-input>
            </el-form-item>
            <el-form-item label="招标类型" prop="tendersType">
              <el-select v-model="ruleForm.tendersType" placeholder="请选择招标类型">
                <el-option label="商超" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="报名时间" prop="dateRange1">
              <el-date-picker v-model="ruleForm.dateRange1" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="onChangeDateRange1"></el-date-picker>
            </el-form-item>
            <el-form-item label="开标时间" prop="tendersTime">
              <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.tendersTime" :picker-options="pickerOptions3" @change="onChangeTendersTime"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="标书名称" prop="tendersName">
              <el-input v-model="ruleForm.tendersName" placeholder="请输入标书名称"></el-input>
            </el-form-item>
            <el-form-item label="缴费金额" prop="payMoney">
              <el-input-number v-model="ruleForm.payMoney" :min="0" :precision="2" placeholder="请输入缴费金额"></el-input-number>
            </el-form-item>
            <el-form-item label="缴费截止时间" prop="payEndTime">
              <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.payEndTime" :picker-options="pickerOptions2" @change="onChangePayEndTime"></el-date-picker>
            </el-form-item>
            <el-form-item label="中标有效时间" prop="dateRange2">
              <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" v-model="ruleForm.dateRange2" :picker-options="pickerOptions4"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="23">
            <el-form-item label="招标公告">
              <VueEditor :config="config" ref="vueEditor" v-model="content" />
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>

      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import VueEditor from "vue-word-editor";
import "quill/dist/quill.snow.css"

export default {
  components: {
    VueEditor
  },
  data () {
    return {
      content: "",
      loading: false,
      submitLoding: false,
      tendersName: '',//标书名称
      tendersNumber: '',//标号
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      dialogVisible: false,
      isAdd: true,
      ruleForm: {
        tendersNumber: '',
        tendersType: '',
        effectiveEndTime: '',
        payEndTime: '',
        effectiveStartTime: '',
        tendersTime: '',
        tendersName: '',
        payMoney: '',
        applicationStartTime: '',
        tendersNotice: '',
        dateRange1: '',
        dateRange2: ''
        // content: ''
      },
      // 报名时间>缴费>开标>中标
      pickerOptions2: {
        disabledDate: (time) => {
          // 当dateRange1还未选择时，可以禁用所有未来日期
          if (!this.ruleForm.dateRange1) {
            return true
          } else {
            return time.getTime() < this.ruleForm.dateRange1[1].getTime();
          }
        }
      },
      // 缴费时间>开标时间
      pickerOptions3: {
        disabledDate: (time) => {
          // 当dateRange1还未选择时，可以禁用所有未来日期
          if (!this.ruleForm.payEndTime) {
            return true
          } else {
            return time.getTime() <= this.ruleForm.payEndTime.getTime();
          }

        }
      },
      // 开标时间>中标时间
      pickerOptions4: {
        disabledDate: (time) => {
          // 当dateRange1还未选择时，可以禁用所有未来日期
          if (!this.ruleForm.tendersTime) {
            return true
          } else {
            return time.getTime() < this.ruleForm.tendersTime.getTime();
          }

        }
      },
      rules: {
        tendersNumber: [{ required: true, message: '请输入姓名', trigger: 'submit' }],
        tendersType: [{ required: true, message: '请选择招标类型', trigger: 'submit' }],
        applicationEndTime: [{ required: true, message: '请选择日期', trigger: 'submit' }],
        applicationStartTime: [{ required: true, message: '请选择日期', trigger: 'submit' }],
        payEndTime: [{ required: true, message: '请选择日期', trigger: 'submit' }],
        effectiveStartTime: [{ required: true, message: '请选择日期', trigger: 'submit' }],
        tendersTime: [{ required: true, message: '请选择日期', trigger: 'submit' }],
        dateRange1: [{ required: true, message: '请选择日期', trigger: 'submit' }],
        dateRange2: [{ required: true, message: '请选择日期', trigger: 'submit' }],
        tendersName: [{ required: true, message: '请输入标书名称', trigger: 'submit' }],
        payMoney: [{ required: true, message: '请输入缴费金额', trigger: 'submit' }],
      },
      config: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            ['link', 'image'],
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction          
          ],
        },
        // 上传图片的配置
        uploadImage: {
          url: this.$axios.defaults.baseURL + this.$api.uploadFile,
          name: "multipartFile",
          // res是结果，insert方法会把内容注入到编辑器中，res.data.url是资源地址
          uploadSuccess: (res, insert) => {
            if (res.data.code !== 100) {
              this.$message.error('请上传图片格式,如JPG,JPEG,PNG文件格式');
            } else {
              insert(res.data.result.fileUrl)
            }
          },

          showProgress: false
        },
      },
      departmentList: [
        {
          departmentId: 1,
          departmentName: '商超'
        }
      ],
      roleList: [],
      startStatus: '',
      options: [{
        value: '1',
        label: '未开始'
      }, {
        value: '2',
        label: '进行中'
      }, {
        value: '3',
        label: '已结束'
      }],

    }
  },

  created () {
    // this.getportalPageList()
  },
  // computed: {
  //   rowstatus () {
  //     return function (row) {
  //       return this.options.find(e => e.value == row.startStatus).label
  //     }
  //   },
  // },
  methods: {
    // 触发清空时间
    onChangeDateRange1 () {
      this.ruleForm = Object.assign({}, this.ruleForm, {
        dateRange2: "",
        payEndTime: "",
        tendersTime: ""
      })
    },
    onChangePayEndTime () {
      this.ruleForm = Object.assign({}, this.ruleForm, {
        dateRange2: "",
        tendersTime: ""
      })
    },
    onChangeTendersTime () {
      this.ruleForm = Object.assign({}, this.ruleForm, {
        dateRange2: "",
      })
    },

    handleInput (value) {
      // 限制金额最多两位小数
      this.ruleForm.payMoney = parseFloat(value).toFixed(2);
    },
    // 招标列表
    getportalPageList () {
      this.loading = true
      this.$axios.get(this.$api.portalPageList, {
        params: {
          page: this.currentPage,
          pageSize: this.pageSize,
          startStatus: this.startStatus,
          tendersName: this.tendersName,
          tendersNumber: this.tendersNumber,
        }
      }).then((res) => {
        this.tableData = res.data.result.list
        this.totalItemsCount = res.data.result.totalCount
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    // 搜索
    onSearch () {
      this.getportalPageList()
    },
    // 新增（对话框）
    handleAdd () {
      console.log(1);
      // this.isAdd = true
      // this.dialogVisible = true
      // this.ruleForm = {};
      this.$router.push({ path: '/portalManagement/detail/demo' });
    },
    // 查看
    handleView (row) {
      this.$router.push({ path: '/biddingManagement/detail/bidListDetails', query: { id: row.tendersId }, });
    },
    // 修改（对话框）
    handleEdit (index, row) {
      this.isAdd = false;
      this.dialogVisible = true;
      this.ruleForm = Object.assign({}, row)
      if (!this.ruleForm.dateRange1) this.ruleForm.dateRange1 = []
      if (!this.ruleForm.dateRange2) this.ruleForm.dateRange2 = []
      // console.log(this.$dayjs(row.effectiveStartTime).toDate());
      // console.log(this.$dayjs(row.effectiveEndTime).toDate());
      this.ruleForm.dateRange1[0] = this.$dayjs(row.applicationStartTime).toDate() //报名开始时间
      this.ruleForm.dateRange1[1] = this.$dayjs(row.applicationEndTime).toDate() //报名结束时间
      this.ruleForm.dateRange2[0] = this.$dayjs(row.effectiveStartTime).toDate()//中标有效开始时间
      this.ruleForm.dateRange2[1] = this.$dayjs(row.effectiveEndTime).toDate()
      //中标有效结束时间
      this.$nextTick(() => {
        let quill = this.$refs.vueEditor.editor;
        quill.clipboard.dangerouslyPasteHTML(row.tendersNotice);
      })

    },
    // 删除
    handleDelete (index, row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios.put(`${this.$api.tendersInfoDelete}/${row.tendersId}`)
          .then((res) => {
            if (res.data.code == 100) {
              this.$message.success(res.data.desc)
            }
            this.getportalPageList()
          }).catch(() => { })
      }).catch(() => { });
    },
    // 切换每页显示的条数
    handleSizeChange (e) {
      this.pageSize = e
      this.getportalPageList()
    },
    // 换页
    handleCurrentChange () {
      this.page += 1
      this.getportalPageList()
    },
    // 关闭对话框
    onCancel () {
      this.dialogVisible = false

      this.$nextTick(() => {
        let quill = this.$refs.vueEditor.editor;
        quill.clipboard.dangerouslyPasteHTML('');
      })

    },
    // 提交表单
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          this.$message.error('提交失败');
          // return console.error('表单提交错误');
        }
        this.submitLoding = true
        let params = {
          applicationStartTime: this.$dayjs(this.ruleForm.dateRange1[0]).format('YYYY-MM-DD'),
          applicationEndTime: this.$dayjs(this.ruleForm.dateRange1[1]).format('YYYY-MM-DD'),
          payEndTime: this.$dayjs(this.ruleForm.payEndTime).format('YYYY-MM-DD'),
          tendersTime: this.$dayjs(this.ruleForm.tendersTime).format('YYYY-MM-DD'),
          effectiveStartTime: this.$dayjs(this.ruleForm.dateRange2[0]).format('YYYY-MM-DD'),
          effectiveEndTime: this.$dayjs(this.ruleForm.dateRange2[1]).format('YYYY-MM-DD'),
          tendersName: this.ruleForm.tendersName,
          tendersNumber: this.ruleForm.tendersNumber,
          tendersType: this.ruleForm.tendersType,
          payMoney: this.ruleForm.payMoney,
          tendersNotice: this.$refs.vueEditor.editor.root.innerHTML
        }
        let url = ''
        let method = ''
        // 根据对话框的状态来判断调起接口
        if (!this.isAdd) {
          params.userId = this.ruleForm.userId
          url = this.$api.tendersInfoUpdate
          method = 'put'
        } else {
          url = this.$api.tendersInfoAdd
          method = 'post'
        }
        this.$axios[method](url, params).then((res) => {
          if (res.data.code == 100) {
            this.$message.success(res.data.desc);
          }
          this.getportalPageList();
          this.submitLoding = false
          this.dialogVisible = false;
          this.resetForm();
        }).catch((err) => {
          this.submitLoding = false
          this.$message.error(err.data.desc);
        });
      });
    },
    // 重置校验
    resetForm () {
      this.ruleForm = {};
      this.$refs.ruleForm.resetFields();
      this.ruleForm.tendersNotice = ""
      this.$nextTick(() => {
        let quill = this.$refs.vueEditor.editor;
        quill.clipboard.dangerouslyPasteHTML('');
      })
    },


  },
}
</script>

<style scoped lang='scss'>
.user {



  ::v-deep .ql-editor {
    min-height: 200px;
  }

  .el-date-editor ::v-deep .el-range-input {
    background: transparent;
  }

  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 200px;
      margin-left: 20px;
    }

    .el-button {
      margin-left: 20px;
    }
  }

  .el-input-number {
    width: 255px;
  }

  .table_list {
    width: 100%;
    max-width: 1560px;
    box-sizing: border-box;
    overflow-x: auto;

    .desc {
      display: -webkit-box;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .red {
      color: #EB2500;
    }

    .green {
      color: #069BBC;
    }

    .gray {
      color: #68706E;
    }

    .logo {
      display: block;
      width: 58px;
      height: 58px;
      border-radius: 4px;
    }

    .busilicense {
      display: block;
      width: 80px;
      height: 58px;
      border-radius: 4px;
    }

    ::v-deep .el-table__fixed-header-wrapper thead tr th {
      color: rgba(3, 16, 14, 0.9);
      background-color: #F4F6F9;
    }


  }

}
</style>
